<template>
    <div class="page">
        <div class="header">
            <el-page-header @back="$router.go(-1)" content="专家登记"/>
        </div>
        <el-row style="margin:15px 0" :gutter="10">
            <el-col :span="14">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>专家登记</span>
                    </div>
                    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
                        <el-row>
                            <el-col :span="18">
                                <el-form-item label="姓名" prop="name">
                                    <el-input v-model="form.name"></el-input>
                                </el-form-item>
                                <el-form-item label="专业">
                                    <el-select
                                            style="width: 100%"
                                            v-model="form.zhuanye"
                                            filterable
                                            clearable
                                            allow-create
                                            default-first-option
                                            placeholder="">
                                        <el-option
                                                v-for="item in selectList.zyList"
                                                :key="item.label"
                                                :label="item.label"
                                                :value="item.label">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="职称">
                                    <el-select
                                            style="width: 100%"
                                            v-model="form.zhicheng"
                                            filterable
                                            allow-create
                                            clearable
                                            default-first-option
                                            placeholder="">
                                        <el-option
                                                v-for="item in selectList.zcList"
                                                :key="item.label"
                                                :label="item.label"
                                                :value="item.label">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" style="text-align: right">
                                <el-upload
                                        class="avatar-uploader"
                                        action="/api/expert/upload"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload">
                                    <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-form-item label="学历">
                            <el-select
                                    style="width: 100%"
                                    v-model="form.xueli"
                                    filterable
                                    clearable
                                    allow-create
                                    default-first-option
                                    placeholder="">
                                <el-option
                                        v-for="item in selectList.xlList"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="简介">
                            <el-input v-model="form.jieshao" type="textarea"
                                      :rows="4"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit" :loading="loading">提 交</el-button>
                            <el-button @click="cancel">取 消</el-button>
                            <el-button type="danger" @click="handeDel" v-if="$route.params.id" style="float: right">删 除</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                rules: {
                    name: [
                        {required: true, message: '名称', trigger: 'blur'},
                    ]
                },
                form: {
                    name: '',
                    zhuanye: '',
                    zhicheng: '',
                    xueli: "",
                    jieshao: '',
                    imageUrl: '',
                },
                selectList: {
                    xlList: [],
                    zcList: [],
                    zyList: []
                }
            }
        },
        methods: {
            handleAvatarSuccess(res, file) {
                this.form.imageUrl = URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            cancel() {
                this.$router.push('/main/expert/expert')
            },
            handeDel(){
                this.$http.post(`/expert/del/${this.$route.params.id}`)
                    .then(resp => {
                        this.$message[resp.data.type](resp.data.msg)
                        if (resp.data.type == 'success') {
                            this.$message.success("删除成功")
                            this.$router.push('/main/expert/expert')
                        }
                    }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
            },
            onSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$http.post('/expert/form', this.form)
                            .then(resp => {
                                this.$message[resp.data.type](resp.data.msg)
                                if (resp.data.type == 'success') this.$router.push('/main/expert/expert')
                            }).catch(err => {
                            this.$message.error(err.message)
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            async getData() {
                try {
                    let pk = this.$route.params.id
                    if (pk) {
                        const resp = await this.$http.get(`/expert/view/${pk}`)
                        if (!resp.data.data) {
                            this.$message.error(resp.data.msg)
                        } else {
                            this.form = resp.data.data
                        }
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            },
            async getSelect() {
                try {
                    const resp = await this.$http.get(`/expert/select`)
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.selectList = resp.data
                    }
                } catch (e) {
                    this.$message.error(e.message)
                }
            }
        },
        mounted() {
            this.getSelect()
            this.getData()
        },
    }
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 130px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }
    .avatar {
        width: 130px;
        height: 150px;
        display: block;
    }
</style>
